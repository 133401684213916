.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4px;
}

.page-item,
.page-link {
  display: inline-flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  height: 35px;
  width: 35px;
  background: #fff;
  overflow-x: auto;
  overflow-y: hidden;
}
.page-item {
  border: 2px solid #fff;
}
.active {
  color: #fff;
  background: #ff5757;
}
.disabled-button {
  visibility: hidden;
}
